.flex {
  display: flex;
}
.f-row {
  flex-direction: row;
}
.f-column {
  flex-direction: column;
}
.f-wrap {
  flex-wrap: wrap;
}
.disabled {
  background-color: var(--cwd4) !important;
  color: grey !important;
}

.center {
  text-align: center;
}

.m-auto {
  margin: auto;
}

.notVisibleByHeightAndOpacity {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
}

.visibleByHeightAndOpacity {
  opacity: 1 !important;
  max-height: 1000px;
  overflow-x: initial !important;
  padding: 50px 0;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.withIcon {
  display: flex;
  align-items: center;
  gap: 0 10px;
}

.withIcon svg {
  /* font-size: 20px; */
}

.iconRotate {
  transform: rotate(90deg);
  transition: transform 0.6s;
}

.fieldError,
.fieldSuccess {
  width: 90%;
  text-align: center;
  margin: auto !important;
  padding: 5px 0;
  font-size: 15px;
}

.fieldError {
  color: var(--errorColor);
  animation: errAnim 0.5s ease 1;
}

.rounded {
  border-radius: 100%;
}

.semiRounded {
  border-radius: 50px;
}

.simpleSkeleton {
  cursor: progress;
  background: linear-gradient(0.25turn, transparent, var(--cwd1), transparent),
    linear-gradient(var(--cwd2), var(--cwd2)),
    radial-gradient(38px circle at 19px 19px, var(--cwd2) 50%, transparent 51%),
    linear-gradient(var(--cwd2), var(--cwd2));
  background-repeat: no-repeat;
  background-size: 315px 250px, 315px 180px, 100px 100px, 225px 30px;
  background-position: -315px 0, 0 0, 0px 190px, 50px 195px;
  animation: simpleSkeletonLoading 1.5s infinite;
}

/* Auth section  */
.authLinksSections {
  display: flex;
  gap: 0 20px;
  margin-bottom: 5px;
}

.authLinksSections a {
  padding: 10px 25px;
  border-radius: 50px;
  border: solid 2px;
}

.authLinksSections a:first-child {
  border-color: var(--colorSecondary);
  color: var(--colorSecondary);
}

.authLinksSections a:last-child {
  border-color: var(--colorPrimary);
  color: var(--colorPrimary);
}
.textCenter {
  text-align: center;
}

@keyframes errAnim {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
