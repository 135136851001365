.dashboard {
  display: flex;
  flex-direction: column;
  gap: 25px 0;
  margin: 50px auto;
  h2 {
    color: var(--colorPrimary);
  }
  b {
    color: var(--colorSecondary);
  }
  h1 {
    width: 90%;
    margin: auto;
  }
}
.dLastInfos h2,
.dGuide h2 {
  font-size: 3vh;
}

.d-welcomeAndHowItWork {
  width: 90%;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
  background-color: #023A7715;
  padding: 20px 30px;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 30px 0;
}

.d-welcomeAndHowItWork>a {
  background-color: var(--colorPrimary);
  color: white;
  padding: 10px 15px;
  border-radius: 50px;
  margin-left: auto;
}

.d-welcome {
  display: flex;
  gap: 20px 0;
  flex-direction: column;
  border-radius: 10px;
  color: grey;
}



/* Last infos */
.dLastInfos {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  h2 {
    width: 100%;
    text-align: center;
  }
  div {
    width: 30%;
    padding: 10px 15px;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
    display: flex;
    flex-direction: column;
    gap: 10px 0;
    p {
      color: grey;
    }
  }
}
/*  */

/*  */
.dGuide {
  line-height: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px 0;
  p {
    color: grey;
  }
  a {
    color: var(--colorPrimary);
  }
}

/*  */


.sorryNotFound {
  background-color: var(--cwd1);
  width: 80%;
  margin: auto;
  padding: 20px 0;
  color: grey;
  text-align: center;
}

.fichier h1,
.agenda h1,
.profil h1,
.ressources h1 {
  color: var(--colorPrimary);
  text-align: center;
  padding: 10px 0;
  margin: 0 auto;
  width: 90%;
  /* border-bottom: solid 1px var(--colorSecondary); */
}

.fichier h2 {
  color: grey;
  text-align: center;
  margin: 10px 0;
}

.ffilesList,
.fAddDoc {
  width: 90%;
  margin: auto;
}

.ffilesList h2,
.fAddDoc h2 {
  color: grey;
  padding: 10px 0;
}

.fSearch {
  text-align: center;
  input {
    border: solid 2px var(--cwd4) !important;
    border-radius: 10px;
    width: 90%;
  }
}

/* File liste table */
table {
  width: 100%;
  margin: 20px 0;
  thead  {
    th{
      color: var(--colorPrimary);
    padding: 15px 0;
    background-color: var(--cpd4);
    font-size: 25px;
    }
  }
  td {
    color: gray;
    padding: 15px 45px;
    text-align: center;
  }
  &:tr:nth-child(even) {
    background-color: var(--cpd4);
  }
  a,button {
  padding: 5px 10px !important;
  background-color: var(--colorPrimary);
  color: white;
  font-size: 14px;
  font-weight: bolder;
  margin: 10px 20px 0 20px;
}

}


/*  */

/* Modal file list */
.modalFileList {
  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
    padding: 10px;
    border-radius: 10px;
    a {
      background-color: var(--colorPrimary);
      color: white;
      padding: 2px 5px;
      font-size: 12px;
      margin-left: 25px;
    }
  }
}

.fAddDoc form .formBtn {
  text-align: left;
}


/* Page Agenda */
.agenda {
  margin: 50px 0;
  &>section {
    margin: auto;
    max-width: 90%;
    padding: 20px 0;
    text-align: center;
    a {
      background-color: var(--colorPrimary);
      color: white;
      text-align: center;
      padding: 10px 15px;
    }
  }
}

.agendaItem {
  background-color: white;
  padding: 10px 15px;
  width: 90%;
  margin: 10px auto;
  h2 {
    color: var(--colorPrimary);
  }
  b {
    color: var(--cpd2);
  }
  p {
    color: grey;
  }
}
/*  */

/*  */
.profil {
  &>section {
    margin: 0 auto 20px auto;
    width: 50%;
    padding: 30px 0;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    form {
      width: 90%;
      margin: auto;
      padding: 50px 0;
    }
    h3 {
      text-align: center;
      color: var(--c2d1);
      font-size: 30px;
    }
  }
  label {
    color: grey;
  }
  input:not([type="radio"]) {
    border: solid 2px var(--cwd1);
  }
}

.radioSelect {
  margin-bottom: 20px;
  width: 80%;
  margin: auto;
}

.profil input[type="radio"] {
  width: initial;
}

.profil .formBtn {
  margin-top: 20px;
}

.form4 b {
  width: 90%;
  margin: auto !important;
  padding: 10px 0;
  font-size: 30px;
  color: grey;
}

.childAdderBtn {
  margin: auto;
  text-align: center;
  width: 80%;
  button {
    background-color: var(--colorPrimary);
    color: white;
  }
}

.upProfilChildList {
  width: 90%;
  margin: 10px auto;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  div {
    padding: 10px 20px;
    margin: 5px 0;
    width: 30%;
    background-color: var(--cpd4);
  }
  b {
    margin-right: 10px;
  }
}
/*  */

/* Cancel subscription page */
.cancelSubPart>section {
  background-color: #ededed;
  padding: 30px 20px;
}

@media screen and (min-width: 512px) and (max-width: 1200px) {
  .profil>section {
    width: 100%;
  }
}

@media screen and (max-width: 512px) {
  .upProfilChildList div {
    padding: 10px 10px;
    width: 90%;
  }

  .ressources>section {
    width: 90%;
  }

  .profil>section {
    width: 90%;
  }
}

/* End fast links */
@media screen and (max-width: 512px) {
  .d-welcome {
    padding: 5px 10px;
  }

  .d-welcome a {
    text-align: center;
  }

  .dLastInfos div {
    width: 90%;
  }
}