.simulationResu {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    padding: 150px 30px 0 30px !important;
}

.simulationResu section {
    width: 50%;
}

.simulationResu>div {
    width: 50% !important;
}

.simulationResu section>div:first-child {
    text-align: center;
}

.simulationResu section>div:first-child>h2 {
    font-weight: 700;
    font-size: 35px;
    color: #3f3f3f;
}

.simulationResu section>div:first-child>div:nth-child(2) {
    font-weight: 700;
    font-size: 68px;
    margin-top: 20px;
    color: #023880;
    letter-spacing: -3.71px;
}

.simulationResu section>div:first-child>div:nth-child(2)>span {
    font-size: 50px;
    font-weight: 400;
    color: #04172f;
    margin-left: 19px;
}

.simulationResu section>div:first-child>div:nth-child(3) {
    color: #5d5d5d;
    font-size: 16px;
    max-width: 600px;
    margin: 10px auto;
    text-align: left;
}

.simulationResu section>div:first-child>h3 {
    font-family: Avenir;
    font-weight: 500;
    font-size: 21px;
    color: #727272;
    margin: 30px 0;

}

.simulationResu section>div:nth-child(2) {}

.simulationResu section>div:nth-child(2)>div {
    display: flex;
    align-items: center;
    gap: 0 20px;
    border-radius: 8px;
    border: 1px solid #d8d8d8;
    padding: 20px;
    margin: 10px auto;
    max-width: 500px;
}

.simulationResu section>div:nth-child(2)>div>div:first-child {
    margin-right: 15px;
    display: flex;
    align-items: center;
}

.simulationResu section>div:nth-child(2)>div>div:first-child>img {
    width: 70px;
    display: block;
    height: 50px;
}


.simulationResu section>div:nth-child(4) {
    text-align: center;
    font-size: 22px;
    max-width: 515px;
    padding: 0;
    margin: 30px auto;
}

.simulationResu>div:nth-child(4)>a {
    border: none;
    width: 100%;
    text-align: center;
    padding: 12px 25px;
    background-color: var(--colorPrimary);
    color: #fff;
    border-radius: 7px;
}









@media screen and (max-width:758px) {

    .simulationResu section,
    .simulationResu>div {
        width: 100% !important;
    }

    .simulationResu>div:first-child>div:nth-child(2) {
        font-size: 48px;
        margin-top: 15px;
    }

    .simulationResu>div:first-child>div:nth-child(2)>span {
        font-size: 30px;
        margin-left: 15px;
    }

    .simulationResu>div:nth-child(2)>div>div:nth-child(2)>div:first-child {
        font-size: 16px;
    }

    .simulationResu>div:nth-child(2)>div>div:first-child>img {
        width: 50px;
    }

    .simulationResu>div:nth-child(2)>div {
        padding: 10px;
    }
}