/* General nav */
header {
    width: 100%;
}

  

.generalNav {
    justify-content: center;
    flex-wrap: wrap;
    display: flex;

}

.generalNav,
.mobileGeneralNav {
    position: fixed;
    transition: all .6s;
    z-index: 1000;
    width: 100%;
    align-items: center;
    background-color: white;
}

.generalNav section {
    display: flex;
    align-items: center;
}


.generalNav hr {
    border: solid 1px var(--ow2);
    margin: initial;
}

.mgn-hambuger,
.gnSearchBar {
    color: var(--colorPrimary);
    cursor: pointer;
}

.mgn-hambuger span {
    margin-right: 5px;
}

.mgn-hambuger ,.gnSearchBar  {
    svg{
        font-size: 30px;
    }
}

.gnSearchBar {
    margin-right: 20px;
    margin-left: 15px;
    b {
        color: var(--colorPrimary);
    }
}


.navSearchForm {
    max-height: 0;
    opacity: 0;
    overflow-y: hidden;
    position: absolute;
    transition: all 0.5s;
    top: 50%;
    width: 90vw;
    left: 5vw;
    right: 5vw;
    background-color: var(--colorPrimary);
    display: flex;
    justify-content: center;
    span {
        width: 10%;
        text-align: center;
    }
    input {
        background-color: transparent;
        width: 80%;
        color: var(--cwd3);
    }
}


.gnLogo {
    padding: 0 15px;
    img {
        max-width: 150px;
    }
}

.gnNavLinks {
    justify-content: space-between;
    div {
        text-align: center;
        a {
            padding: 0 20px;
        }
         a,span {
            color: var(--colorPrimary);
            cursor: pointer;
        }
    }
}




.gn-linkWithSubLinks {
    width: 100%;
    padding: 20px 0;
    display: flex;
    justify-content: space-evenly;
    position: relative;
    background-color: var(--colorPrimary);
   & >div {
        color: white;
        &>b {
            text-align: center;
            cursor: pointer;
        }
    }
}

.subMenuLevel1 {
    z-index: 120000;
    position: absolute;
    width: 90%;
    background-color: var(--colorPrimary);
    top: 100%;
    transition: all 0.5s;
    article {
        width: 90%;
        margin: auto;
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        gap: 25px;
        /* background-color: green; */
        a {
            /* justify-content: center; */
            width: 30%;
            color: white;
            b {
                width: 80%;
            }
        }
    }
}

.mobileGeneralNav {
    display: none;
}


/* Auth nav */
.authedNav {
    width: 100%;
    padding: 20px 15px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    display: flex;
    align-items: center;
}

.an-sidebarToggler {
    margin-left: auto;
    margin-right: 10px;
    cursor: pointer;
    justify-content: center;
    span {
        background-color: var(--colorPrimary);
        color: white;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    svg {
        font-size: 30px;
    }
} 

.an-logout {
    cursor: pointer;
    font-size: 25px;
}

.an-logo {
    width: 100px;
    display: flex;
    img {
        width: 100%;
    }
}


/*  */


@media screen and (max-width: 1024px) {
    .generalNav {
        display: none !important;
    }

    .gnLogo img {
        max-width: 100px;
    }

    .mobileGeneralNav {
        justify-content: space-between;
        padding: 0 10px;
        display: flex;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
}

@media screen and (max-width: 512px) {
    .searchModal {
        height: 80vh;
    }

    .notVisileOnPC {
        display: block;
    }


}