.simulateur-enfants>span {
  font-weight: 500;
  font-size: 30px;
  text-align: center;
  display: inline-block;
  width: 100%;
}

.enfants-ajout {
  max-width: 520px;
  margin: 30px auto 0;
}

.enfants-ajout>div {
  padding: 22px 0;
  width: 100%;
  border: 1px dashed var(--colorPrimary);
  border-radius: 5px;
  font-size: 22px;
  font-weight: 700;
  color: var(--colorPrimary);
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  cursor: pointer;
}

.enfants-map {
  max-width: 520px;
  margin: 30px auto 0;
}

.enfants-map>p {
  color: #b9b9b9;
  font-size: 22px;
  text-align: center;
  margin: 0 0 20px;
}

.enfants-map>div {
  background: #fff;
  border: 1px dashed #d1d1d1;
  padding: 30px 20px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.enfants-map>div>div:first-child {
  display: flex;
}

.enfants-map>div>div:first-child>div:first-child {
  flex-grow: 2;
}

.enfants-map>div>div:first-child>div:first-child>div:first-child {
  font-size: 24px;
  margin-bottom: 5px;
  font-weight: 500;
}

.enfants-map>div>div:first-child>div:first-child>div:not(:first-child) {
  background: #d5e5fb;
  border-radius: 10px;
  color: #1174ea;
  font-size: 20px;
  padding: 4px 8px;
  display: inline-block;
  margin: 0 3px 3px 0;
  font-weight: 500;
}

.enfants-map>div>div:first-child>div:last-child {
  width: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.enfants-map>div>div:first-child>div:last-child>i {
  font-size: 28px;
  color: #b9b9b9;
  cursor: pointer;
}

.enfants-map>div>div:last-child {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #e9e9e9;
}

.enfants-map>div>div:last-child>div {
  display: flex;
  padding: 2px 0;
  align-items: center;
}

.enfants-map>div>div:last-child>div>div:first-child {
  flex-grow: 2;
  color: #bfbfbf;
  font-size: 18px;
}

.enfants-map>div>div:last-child>div>div:last-child {
  color: #000;
  font-size: 22px;
  text-align: right;
  font-weight: 500;
}

/* .simulateur-enfants>div:nth-child(2){
    border-radius: 10px;
    margin: 15px auto;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.simulateur-enfants>div:nth-child(2)>div{
    position: relative;
    width: 90%;
    max-width: 514px;
    margin-bottom: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #d1cfcf;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.3s ease;
    padding: 17px 15px;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
}
.simulateur-enfants>div:nth-child(2)>div:hover{
    color: var(--colorPrimary);
    border: 1px solid var(--colorPrimary);
}
.simulateur-enfants>div:nth-child(2)>div.selected{
    color: white;
    background-color: var(--colorPrimary);
    border: 1px solid var(--colorPrimary);
} */
@media screen and (max-width: 700px) {
  .simulateur-age.revenu {
    flex-direction: row !important;
  }
}

@media screen and (max-width: 500px) {
  .simulateur-enfants>span {
    font-size: 25px;
  }

  .enfants-map>div>div:first-child>div:last-child {
    width: 44px;
  }

  .enfants-map>div>div:first-child>div:last-child>i {
    font-size: 22px;
  }

  .enfants-map>div>div:first-child>div:first-child>div:first-child {
    font-size: 19px;
  }

  .enfants-map>div>div:first-child>div:first-child>div:not(:first-child) {
    font-size: 16px;
  }

  .enfants-map>div {
    padding: 15px 10px;
  }

  .enfants-map>div>div:last-child>div>div:first-child {
    font-size: 16px;
  }

  .enfants-map>div>div:last-child>div>div:last-child {
    font-size: 17px;
  }
}