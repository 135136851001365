.loaderBorderContainer {
  /* background-color: rgba(0,0,0,0.3); */
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(8px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1000;
}
.loaderBorderContainer > h1 {
  /* color: #1c1b29; */
  font-weight: 600;
  margin-bottom: 3rem;
  color: transparent;
  -webkit-text-stroke: 2px rgba(28, 27, 41, 0.4);
  position: relative;
  width: fit-content;
}
.loaderBorderContainer > h1::before {
  content: attr(data-text);
  color: #1c1b29;
  position: absolute;
  top: 0px;
  left: 0;
  border-right: 4px solid #1c1b29;
  -webkit-text-stroke: 0px;
  width: 0;
  height: 100%;
  overflow: hidden;
  animation: moveAnim 6s ease-out infinite;
  white-space: nowrap;
}
@keyframes moveAnim {
  0%,
  10%,
  100% {
    width: 0;
  }
  70% {
    width: 103%;
  }
}

.loaderBorderContainer > div {
  height: 175px;
  width: 200px;
  position: relative;
  margin: 0 auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0 20px 35px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  overflow: hidden;
  background-color: #1c1b29;
}
.loaderBorderContainer > div:before {
  content: "";
  background-image: conic-gradient(#04b0ee 20deg, transparent 120deg);
  height: 150%;
  width: 150%;
  position: absolute;
  left: -25%;
  top: -25%;
  animation: rotate 2s infinite linear;
}
@keyframes rotate {
  100% {
    transform: rotate(-360deg);
  }
}
.loaderBorderContainer > div:after {
  content: "Loading...";
  height: 94%;
  width: 94%;
  position: absolute;
  background-color: #1c1b29;
  border-radius: 5px;
  top: 3%;
  left: 3%;
  color: #04b0ee;
  display: grid;
  place-items: center;
  font-size: 20px;
  letter-spacing: 6px;
}

@media screen and (max-width: 500px) {
  .loaderBorderContainer > h1 {
    font-size: 26px;
  }
  .loaderBorderContainer > div {
    width: 170px;
    height: 125px;
  }
  .loaderBorderContainer > div:after {
    font-size: 18px;
  }
}
