:root {
  --colorPrimary: #023a77;
  --cpd1: #003377;
  --cpd2: #0063e6;
  --cpd4: #e3efff;
  --colorSecondary: #e3252e;
  --c2d1: #de403b;
  --c2d1: #e56b66;

  /* --trustpilotColor: #00b67a; */
  --trustpilotColor: #0063e6;

  --sideBarWidth: 100%;
  --authedSidebarWidth: 250px;
}

body {
  scroll-behavior: smooth;
}

.errorField {
  color: var(--errorColor) !important;
}

.successField {
  color: var(--successColor) !important;
}

.errorField,
.successField {
  width: 100%;
  text-align: center;
}

html {
  height: 100%;
}

body {
  overflow-x: hidden;
  height: 100%;
  width: 100%;
}

.btnDisable {
  background-color: var(--cwd2) !important;
  color: grey !important;
}

.bonyNotScrollable {
  overflow-y: hidden !important;
}

main {
  /* overflow: hidden; */
  display: flex;
  flex-wrap: wrap;
}

main > div:not(.Toastify, .home) {
  min-width: 80%;
  margin: 155px auto !important;
}

.home {
  min-width: 80%;
}

.input-error-msg {
  margin: 10px auto;
  color: red;
}

.btnDisabled {
  color: grey !important ;
  background-color: #efefef !important;
}

@font-face {
  font-family: "Spartan-Bold";
  src: url("../fonts/Spartan-Bold.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Spartan-ExtraBold";
  src: url("../fonts/Spartan-ExtraBold.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Spartan-Regular";
  src: url("../fonts/Spartan-Regular.ttf");
  font-display: swap;
}

body {
  font-family: "Spartan-Regular";
}

p {
  /* line-height: 35px; */
}

h1,
h2,
h3,
h4 {
  /* line-height: 6vh; */
}

b,
h1,
h2,
h3,
h4 {
  font-family: "Spartan-ExtraBold";
}

a,
input,
select,
textarea {
  font-family: "Spartan-Regular";
}

p,
span,
i,
label,
b,
h1,
h2,
h3,
h4,
h5 {
}

a,
b {
  display: inline-block;
}

.page404NotFound {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.page404NotFound img {
  height: 50vh;
  object-fit: contain;
}

.page404NotFound h1 {
  margin-top: 25px;
  color: var(--c2d1);
  margin-bottom: 10px;
}

.page404NotFound a {
  background-color: var(--c2d1);
  color: white;
  padding: 15px 25px;
  margin: 0 auto;
}

.isDropDownVisibleByHeightAnimation {
  max-height: 1000px !important;
  padding: 10px 0 !important;
  overflow: inherit !important;
  opacity: 1 !important;
}

.disapearOnScroll {
  opacity: 0;
  max-height: 0;
  overflow: hidden !important;
  padding: 0 !important;
}

.shadowOnScroll {
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
}

.notVisileOnPC {
  display: none;
  transition: all 0.5s;
  cursor: pointer;
}

/* Main */
main {
  transition: all 0.5s ease;
}

main.mainMoveRight {
  transform: translateX(calc((-1) * var(--sideBarWidth)));
}

main.mainAuthMoveRight {
  transform: translateX(var(--authedSidebarWidth));
}

.simulatorFixedLink a {
  border-radius: 50px;
  background-color: var(--colorPrimary) !important;
  padding: 10px 25px !important;
  color: white;
  animation: bouncer 2s infinite ease;
  box-shadow: var(--colorPrimary) 0px 0.0625em 0.0625em,
    var(--colorPrimary) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}

.floatBtnVisible {
  opacity: 1;
  max-height: 200px;
}

@keyframes bouncer {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

/*  */

/* Paiement Info */
.paiementResumé {
  padding: 40px 30px 200px 30px;
  width: 35%;
  background-color: #023a7710;
  color: var(--colorPrimary);
  border-radius: 15px;
  background-image: url("../images/sectionBgs/signup.png");
  background-repeat: no-repeat;
  background-position: 110% 110%;
}

.paiementResumé div b {
  font-size: 4vh;
  margin-right: 5px;
}

/*  */
.endPageLink {
  width: 90%;
  margin: 30px auto;
  text-align: center;
}

.endPageLink a {
  background-color: var(--colorSecondary);
  color: white;
  padding: 10px 25px;
}

/* SearchBar  */
.searchModal {
  height: 80vh;
  width: 40%;
  z-index: 999999 !important;
}

.searchBar {
  /* background-color: palevioletred; */
  display: flex;
  width: 90%;
  margin: auto;
  flex-direction: column;
}

.sb-input {
  display: flex;
  flex-direction: column;
}

.sb-input input {
  width: 100%;
  background-color: var(--cwd1);
}

.sb-results {
  display: flex;
  margin-top: 10px;
  flex-direction: column;
}

.sb-results div {
  margin: 5px 0;
  width: 100%;
  background-color: var(--cwd1);
  text-align: left;
  padding: 10px;
}

.sb-results div a {
  color: var(--colorPrimary);
}

/*  */

.successOperationModal {
  text-align: center;
  color: var(--colorPrimary);
}

.successOperationModal img {
  height: 50vh;
}

.testStripe {
  padding: 200px 0 50px 0;
}

.simulationResu {
  padding-top: 50px !important;
}

.simulationResuTakeContact {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 95%;
  margin: auto;
}

.simulationResuTakeContact img {
  width: 200px;
  margin: auto;
}

.simulationResuTakeContact h2 {
  width: 100%;
  text-align: center;
  color: var(--c2d1);
  word-break: break-all;
}

.simulationResuTakeContact ul {
  list-style: none;
  border: solid 2px var(--cpd4);
  border-radius: 10px;
  color: var(--cpd1);
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px 0;
  margin: auto;
}

.simulationResuTakeContact ul li {
  display: flex;
  gap: 0 10px;
}

.simulationResuTakeContact ul li svg {
  font-size: 30px;
}

.simulationResuTakeContact section {
  width: 90%;
  margin: auto;
  display: flex;
  align-items: center;
  gap: 20px;
  flex-direction: column;
}

.simulationResuTakeContact a {
  text-align: center;

  padding: 15px 20px;
  border-radius: 10px;
}

.simulationResuTakeContact a {
  background-color: var(--colorPrimary);
  color: white !important;
}

.simulationResuTakeContact p {
  color: var(--colorPrimary);
  text-align: center;
}

/* Element not found */
.notFound {
  width: 60%;
  margin: auto;
  text-align: center;
}

.notFound img {
  height: 40vh;
  object-fit: contain;
}

/* Fin Element not found */

/* Banner */
.dataListEmptySimpleMessage {
  text-align: center;
  margin: 40px auto;
}

.dataListEmptySimpleMessage p {
  font-size: 4vh;
}

/*  */

/* Simulation btn */
.makeSimulationLink {
  background-color: var(--colorSecondary);
  color: white;
  padding: 15px 30px;
  border-radius: 30px;
}

.makeSimulationCallToAction1 {
  margin: 30px 0;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 10px 0;
}

.makeSimulationCallToAction1 a {
  margin: auto;
}

/*  */

@media screen and (max-width: 1024px) {
  .gn-linkWithSubLinks {
    display: none !important;
  }

  .gnNavLinks {
    display: none !important;
  }

  .mgn-hambuger {
    display: flex !important;
    align-items: center;
  }
}

@media screen and (max-width: 758px) {
  main > div:not(.Toastify, .home) {
    margin: 75px auto !important;
  }

  .paiementResumé {
    width: 90%;
  }
}

@media screen and (max-width: 512px) {
  /*  */

  /*  */
}
