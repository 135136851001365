.simulations-guides {}

.sg-allSimulations,
.sg-simulationInfos,
.sg-allGuides,
.sg-guideInfos {
  width: 90%;
  margin: auto;
}

.sg-allSimulations,.sg-allGuides {
  h1 {
    text-align: center;
    color: var(--colorPrimary);
    padding: 10px 0;
    font-size: 5vh;
  }
  p{
    text-align: center;
  padding: 5px 0;
  font-size: 3vh;
  color: grey;
  }
  div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    div {
      width: 30%;
      border: solid 2px var(--cpd4);
      background-color: var(--cwd1);
      padding: 10px 20px;
      margin: 10px 0;
      display: flex;
      flex-direction: column;
      b {
        color: grey;
        padding: 20px 0;
        text-transform: uppercase;
        text-align: center;
      }
      a {
        text-align: center;
        color: var(--c2d1);
        text-decoration: underline;
      }
      h3 {
        color: var(--c2d1);
        padding: 10px 0;
      }
      h1 {
        color: var(--colorPrimary);
        padding: 20px 0;
      }
    }
  }
}

.sg section:last-child p,
.sg section:last-child span,
.sg section:last-child ul,
.sg section:last-child li {
  color: gray;
}

.sg-simulationInfos table {
  margin: 20px 0;
  border: solid 2px var(--cwd2);
  text-align: center;
  tr,th,td{
    border: solid 1px var(--cwd2);
  }
  th {
    padding: 20px;
    color: var(--cpd2);
  }
}


/*  */
.sg-link {
  margin: auto;
  text-align: center;
  margin: 20px 0;
  a {
    background-color: var(--colorSecondary);
    color: white;
    padding: 10px 35px;
  }
}

@media screen and (max-width: 512px) {
  .sg-allSimulations>div div {
    width: 95%;
    text-align: center;
  }
}