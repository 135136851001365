.simulateur-age.name>div>input {
    width: 100% !important;
    min-width: 135px;
    margin: 20px auto !important;
    font-size: 40px;
    color: #3f7bc9 !important;
}


#recaptcha-container{
    width: 100%;
    height: 100%;
    margin: 10px auto;
}
#recaptcha-container>div{
    width: fit-content;
    margin: 0 auto;
}

.form-otp{
    margin: 10px;
    padding: 20px;
text-align: center;
}


@media screen and (max-width: 700px)
{
    .simulateur-age.name>div>input {
        width: 100% !important;
        min-width: 135px;
        margin: 20px auto !important;
        font-size: 33px !important;
        color: #3f7bc9 !important;
    }
}