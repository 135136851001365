.legality {
  width: 90%;
  margin: auto;
  padding: 200px 0 20px 0;
  section {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    padding: 50px 20px;
  }
  h1 {
    color: var(--colorPrimary);
    text-align: center;
    border-bottom: solid 2px var(--colorPrimary);
    padding: 5px 0;
  }
  h3 {
    color: var(--colorSecondary);
    font-size: 3vh;
    padding: 10px 0;
    margin: 10px 0;
  }
  a {
    color: var(--cpd3);
  }
  strong {
    color: var(--cpd2);
    margin: 0 5px;
  }
}

.pdc,
.ml,
.cguv {
  width: 90%;
  margin: auto;
  color: grey;
  b {
    padding: 10px 0;
    color: var(--c2d1);
    display: block;
  }
}


@media screen and (max-width: 512px) {
  .legality {
    width: 100%;
  }
}