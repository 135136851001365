.pageBannerType1 {
  width: 90%;
  margin: 115px auto 25px auto;
  height: 25vh;
  background-color: var(--colorPrimary);
  border-radius: 16px;
  background-image: url("../../images/forBanner.webp");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 3.5vh;
  text-align: center;
}

.pageTitleType1 {
  max-width: 90%;
  margin: 25px auto;
  h2 {
    position: relative;
    font-size: 34px;
  }
  &::after {
    content: "";
    position: absolute;
    width: 5px;
    height: 100%;
    background-color: #f4433690;
    /* top: 40%; */
    top: 0;
    left: -10px;
    z-index: -2;
  }
}

.arcticleContent,
.similarArticle {
  padding: 50px 100px;
}

.ac-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  color: grey;
  b {
    color: var(--colorSecondary);
  }
  a {
    padding: 10px 15px;
    border-radius: 8px;
    color: white;
    background-color: var(--colorSecondary);
  }
}
.arcticleContent {
  img {
    margin: 30px 0;
    width: 100%;
    object-fit: cover;
  }
  h2 {
    margin: 30px 0;
  }
  .acrticleContent-firstPart,
  .acrticleContent-secondPart {
    p {
      color: #7a7a7a;
      font-size: 18px;
    }
  }
}

/* ---------------- article similiaire ------------------ */
.articles-container {
  margin-top: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
  width: 90%;
  margin: auto;
}

.articleCard {
  padding: 20px;
  color: black;
  transition: 0.3s all;
  &:hover {
    background-color: #f7f7f7;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 10px;
  }
  .article-img {
    position: relative;
    span {
      position: absolute;
      bottom: 0;
      background-color: #f44336;
      color: white;
      padding: 15px;
      left: 70%;
      transform: translateY(50%);
    }
  }
}

.articleCard {
  img {
    width: 100%;
    height: 350px;
    object-fit: cover;
  }
  .article-author {
    color: #7a7a7a;
    margin-left: 30px;
  }
  .article-title {
    margin: 15px 0;
  }
  .article-desc {
    color: #7a7a7a;
    margin: 15px 0;
    overflow: hidden;
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }
  .article-category {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #f44336;
  }
}

.articleCard .article-category span:first-child {
  border: 1px solid #f44336;
  width: 25%;
  color: #f44336;
}

.articleCard {
  &:hover {
    .article-information div span {
      color: #f44336;
    }
  }
  .article-information {
    display: flex;
    align-items: center;
    justify-content: space-around;
    div {
      display: flex;
      gap: 10px;
      color: #7a7a7a;
      span {
        color: #023a77;
        transform: 0.3s all;
      }
    }
  }
}
/*  */

.articleCommentSection {
  width: 90%;
  margin: auto;
}

.accCommentList,
.accCommentResponsesList {
  display: flex;
  flex-direction: column;
  gap: 25px 0;
}

.commentSection {
  display: flex;
  gap: 0 20px;
  & > span {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    line-height: 50px;
    color: grey;
    text-align: center;
    background-color: #efefef;
  }
  & > div {
    max-width: 80%;
    display: flex;
    flex-direction: column;
  }
}

.cs-actions {
  display: flex;
  gap: 0 5px;
}

.cs-actions button:first-child {
  background-color: transparent;
  border: solid 2px grey;
  color: grey;
}

.cs-actions button:last-child {
  background-color: transparent;
  color: grey;
}

.commentForm,
.accCommentResponsesList {
  max-height: 0;
  opacity: 0;
  overflow-y: hidden;
  transition: all 500ms ease-in;
}

.accCommentResponsesListVisible {
  max-height: 600px;
  overflow: initial;
  opacity: 1;
  padding: 25px 0;
}

.commentFormVisible {
  padding: 25px 0;
  max-height: 600px;
  overflow: initial;
  opacity: 1;
}
/*  */

/* ----------------- responsive ------------------ */
@media screen and (max-width: 968px) {
  .articles-container {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 768px) {
  .arcticleContent,
  .similarArticle {
    padding: 50px;
  }
}

@media screen and (max-width: 468px) {
  .arcticleContent,
  .similarArticle {
    padding: 50px 20px;
  }
}
