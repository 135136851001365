.souscription,
.souscription-result {
  padding: 200px 0 20px 0;
  /* display: flex; */
}

.souscription {
  h1 {
    margin: 10px 0;
    text-align: center;
    color: var(--colorPrimary);
    padding: 10px 0;
  }
  &>div {
    width: 90%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
  }
}

.sForm {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  button {
    color: white;
    background-color: var(--c2d1);
    padding: 20px 30px;
  }
}

.subscriptionForm {
  width: 90%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  color: gray;
  input {
    border-bottom: solid 2px var(--cwd2);
  }
  button {
    width: 100%;
  }
}

.formTotalPrice {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 5px auto;
  p {
    color: var(--cpd2);
  }
}


/* Result */
.souscription-result {
  section {
    max-width: 80%;
    margin: auto;
    text-align: center;
  }
  img {
    height: 50vh;
  }
  h1 {
    font-size: 30px;
    color: var(--colorSecondary);
  }
}
/*  */

@media screen and (max-width: 512px) {
  .sForm {
    width: 100%;
  }
}