/*  */
.contact {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  &>section {
    width: 45%;
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    margin: 20px 0;
    h2 {
      margin-bottom: 20px;
    }
    h2,h4 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    text-transform: uppercase;
  }
  }
}

.c-ourInfos {
  background-color: var(--colorPrimary);
  color: white;
  border-radius: 16px;
  background-image: url('../../images/sectionBgs/contact.png');
  background-position: 110% 110%;
  background-repeat: no-repeat;
  div {
    width: 90%;
    margin: 20px auto;
  }
  article {
    margin: auto auto 0 auto;
    width: 90%;
    display: flex;
    gap: 0 10px;
  }
}
.socialIcon {
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 45px;
  border-radius: 100%;
}

.cSendMessageForm {
  border-radius: 20px;
  form {
    margin: auto;
    a {
      background-color: var(--colorPrimary);
      color: white;
      padding: 10px 25px;
    }
  }
}

.contact button {
  width: 100%;
}

@media screen and (max-width: 758px) {
  .contact section {
    width: 90%;
  }
}