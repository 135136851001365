/* Sidebar general */
aside.mobileGeneralSidebar {
    z-index: 11000;
    position: fixed;
    right: calc((-1) * var(--sideBarWidth));
    top: 0;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    width: var(--sideBarWidth);
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.mobileGeneralSidebarVisible {
    right: 0 !important;
}


.sidebarShowSubMenuLevel1,
aside.mobileGeneralSidebar {
    display: flex;
    flex-direction: column;
    transition: all 0.5s ease;
    gap: 20px 0;
    padding: 30px 25px;
}

aside.mobileGeneralSidebar{
    .mgs-closer {
        display: flex;
        
        p {
            font-size: 20px;
            font-weight: bold;
            align-items: center;
            gap: 0 10px;
            cursor: pointer;
            color: var(--c2d1);
        }
    }
}


aside.mobileGeneralSidebar section:not(:first-child),
aside.mobileGeneralSidebar>div>a {
    justify-content: space-between;
    cursor: pointer;
}


.sidebarShowSubMenuLevel1 {
    overflow-y: auto;
    background-color: white;
    z-index: 11001;
    position: absolute;
    height: 100%;
    top: 0;
    left: 100%;
    width: 100%;
}

.subLinkToLeft {
    left: 0 !important;
}

.sidebarLinksList {
    display: flex;
    flex-direction: column;
    gap: 20px 0;
   a, span {
    color: grey;
}
p{
    justify-content: space-between;
}

}


/* Authed sidebar */
.an-sidebar {
    /* background-color: var(--colorPrimary); */
    background-color: white;
    position: fixed;
    top: 0;
    height: 100%;
    width: var(--authedSidebarWidth);
    transition: all .5s ease;
    display: flex;
    flex-direction: column;
    padding: 25px 0;
    overflow-y: auto;
    .ans-header {
        cursor: pointer;
        background-color: white;
        width: 40px;
        height: 40px;
        margin-left: 10%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 30px;
        img {
            width: 100%;
            max-height: 65px;
        }
    }
    
    .ans-body {
        margin: 25px auto 0 auto;
        width: 80%;
        display: flex;
        flex-direction: column;
        gap: 10px 0;
        a {
            color: var(--colorPrimary);
            padding: 10px 0;
        }
    }
    
    .ans-footer {
        margin: 25px auto 0 auto;
        width: 80%;
        color: white;
    }
}

.ans-show {
    left: 0;
}

.ans-hide {
    left: calc(-1 * var(--authedSidebarWidth));
}



/*  */