.faq {
  margin: 25px auto;
    h2,p {
    text-align: center;
    max-width: 90%;
    margin: auto;
  }
  h2 {
    color: var(--colorPrimary);
    font-size: 5vh;
  }
  section {
    width: 80%;
    margin: 20px auto;
    background-color: white;
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    h4 {
      padding: 0 0 25px 0;
      border-bottom: solid 2px var(--colorPrimary);
      width: 90%;
      margin: 0 auto 25px auto;
      font-size: 4vh;
      color: var(--colorPrimary);
    }
    div {
      width: 90%;
      margin: 10px auto;
    }
  }
}

.questionAndResponseLayout {
  background-color: #efefef;
  b {
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  span {
    width: 80%;
  }
  svg {
    font-size: 30px;
    color: gray;
  }
  p {
    overflow: hidden;
    max-height: 0;
    opacity: 0;
    transition: all 500ms;
  }
}

.qarl-responseVisible {
  max-height: 500px !important;
  overflow: initial;
  opacity: 1 !important;
  padding: 30px 20px;
  background-color: white;
}

@media screen and (max-width: 512px) {
  .faq section {
    width: 95%;
  }
}