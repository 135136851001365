 .affiliation-desc{
    width: 100%;
    .ad-afterBanner{
        width: 90%;
        margin: 50px auto;
        h2{
            color: var(--colorPrimary);
            text-align: center;
            margin-bottom: 25px;
        }
        .adab-stepDesc{
            justify-content: space-evenly;
            article{
                width: 30%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                span{
                    width: 50px;
                    height: 50px;
                    line-height: 50px;
                    background-color: var(--colorSecondary);
                    color: white;
                    text-align: center;
                    display: block;
                    border-radius: 100%;
                    margin-bottom: 15px;
                }
                p{
                    text-align: center;
                }
            }
        }
    }
}
.ad-banner{
    background-color: var(--colorPrimary);
    align-items: center;
    justify-content: space-evenly;
    min-height: 60vh;
    section{
        width: 45%;
        color: white;
        gap: 15px 0;
        align-items: flex-start;
        h1{
            font-size: 35px;
        }
        button{
            background-color: white !important;
            color: var(--colorPrimary) !important;
        }
        
        
    }
    img{
        height: 100%;
        width: 45%;
        clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 25% 100%, 0% 50%);
    }
}


.become-referror{
    justify-content: space-evenly;
    >section{
        width: 45%;
        &:first-child{
            padding: 50px 0;
            h1{
                color: var(--colorPrimary);
            }
            gap: 15px 0;
            b{
                margin: 4px 0;
                display: block;
                color: var(--colorSecondary);
            }
        }

        &:nth-child(2){
            background-color: white;
            padding: 25px 0;
            border-radius: 20px;
            form{
                width: 90%;
                margin: auto;
            }
        }
    }
}

@media screen and (max-width: 758px) {
    .affiliation-desc{
        .ad-banner{
            gap: 25px;
            section{
                width: 90%;
            }
            img{
                width: 90%;
                clip-path: initial;
            }
        }

        .ad-afterBanner{
            width: 90%;
            .adab-stepDesc{
                gap: 50px 0;
                article{
                    width: 100%;
                }
            }
        }
    }

    .become-referror{
        gap: 25px 0;
        >section{
            width: 90%;     
        }
    }
} 