@import './variables.module.scss';

@import './uikits/nav.scss';
@import './uikits/footer.scss';
@import './uikits/sidebar.scss';
@import './uikits/form.scss';
@import './uikits/modal.scss';

@import './pages/accueil.scss';
@import './pages/contact.scss';

@import './pages/blog.scss';
@import './pages/faq.scss';
@import './pages/guides-simulations.scss';
@import './pages/auth.scss';
@import './pages/about.scss';
@import './pages/reference.scss';

@import './pages/dashboard.scss';
@import './pages/legal.scss';
@import './pages/prelevement.scss';
@import './pages/ressourceAndDoc.scss';
@import './pages/souscription.scss';



button {
    background-color: var(--colorPrimary);
    color: white;
    border-radius: 10px;
    padding: 15px 25px;
    a{
        color: unset;
    }
  }

.appSectionSpinner{
    width: 90%;
    margin: 50px auto;
    font-size: 100px;
    color: var(--colorPrimary);
    text-align: center;
}

  .fullScreenLoader{
    z-index: 99999999999;
    height: 110vh;
    width: 100%;
    background-color: var(--colorPrimary);
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    .fsl-loader {
        --c:no-repeat linear-gradient(white 0 0);
        background: 
          var(--c),var(--c),var(--c),
          var(--c),var(--c),var(--c),
          var(--c),var(--c),var(--c);
        background-size: 16px 16px;
        animation: 
          l32-1 1s infinite,
          l32-2 1s infinite;
      }
      @keyframes l32-1 {
        0%,100% {width:45px;height: 45px}
        35%,65% {width:65px;height: 65px}
      }
      @keyframes l32-2 {
        0%,40%  {background-position: 0 0,0 50%, 0 100%,50% 100%,100% 100%,100% 50%,100% 0,50% 0,  50% 50% }
        60%,100%{background-position: 0 50%, 0 100%,50% 100%,100% 100%,100% 50%,100% 0,50% 0,0 0,  50% 50% }
      }
  }
  /* HTML: <div class="loader"></div> */
