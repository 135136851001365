/*  */

.appSomeReview {
    width: 90% !important;
    margin: auto !important;
}

.appSomeReview h2 {
    color: var(--colorPrimary) !important;
    text-align: center;
    margin: 25px auto;
}

.asr-reviewsList .slick-slide {
    padding: 0 20px;
}

/* .asr-reviewsList .slick-list {
    margin: 0 -20px;
} */

.asr-reviewBox {
    background-color: #efefef;
    border-radius: 30px;
    padding: 30px;
    width: 300px;
    display: flex !important;
    gap: 20px 0;
    flex-direction: column;
}


.asrr-head {
    display: flex;
    gap: 0 10px;
    align-items: center;
}


.asrr-head svg {
    color: grey;
    font-size: 25px;
}

.activeStar {
    color: var(--trustpilotColor) !important;
}

.asrr-head img {
    width: 50px;
    height: 50px;
    border-radius: 60px;
}

.asrr-body p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.asrr-body span {
    text-align: right;
    font-size: 12px;
    width: 100%;
}

.asrr-foot {
    display: flex;
    align-items: center;
    gap: 0 5px;
}

.asrr-foot img {
    width: 80px;
}

.asr-viewAllReviews {
    width: 100%;
    text-align: center;
    margin: 25px 0;
}

.asr-viewAllReviews b {
    color: var(--trustpilotColor);
}