.simulateur-age.loyer>div>input{
    width: 280px !important;
    min-width: 135px;
margin: 20px 10px !important;
font-size: 80px;
color: #3f7bc9 !important;
}
.loyer>span{
    font-size: 80px !important;
    color: #1174ea;
}
.simulateur-age.bien>div>input{
width: 320px !important;
}

.loyer-wrap>span{
    font-weight: 500;
    font-size: 30px;
    text-align: center;
    display: inline-block;
    width: 100%;
}


.simulateur-age.postal{
    flex-direction: column !important;
}

.simulateur-age.postal>span{
    color: #5d5d5d !important;
    text-align: center !important;
    font-size: 15px !important;
}


@media screen and (max-width:700px) {
    .simulateur-age.loyer{
    flex-direction: row !important;
    }
    .simulateur-age.postal{
        flex-direction: column !important;
    }
.simulateur-age.loyer>div>input{
width: 185px !important;
font-size: 40px !important;
}
.loyer>span{
    font-size: 40px !important;

}
}
@media screen and (max-width:500px) {
    .loyer-wrap>span{
        font-size: 25px;
    }
}