.slick-slide[aria-hidden=true] a[href],
.slick-slide[aria-hidden=true] area[href],
.slick-slide[aria-hidden=true] input:not([disabled]),
.slick-slide[aria-hidden=true] select:not([disabled]),
.slick-slide[aria-hidden=true] textarea:not([disabled]),
.slick-slide[aria-hidden=true] button:not([disabled]),
.slick-slide[aria-hidden=true] [tabindex]:not([disabled]),
.slick-slide[aria-hidden=true] [contenteditable=true]:not([disabled]) {
  display: none;
}


.home {
  display: flex;
  flex-wrap: wrap;
}

.home>div:not(:first-child) {
  margin: 30px auto;
  padding: 30px 0;
  display: flex;
  flex-direction: column;
}

.home>div h2 {
  color: black;
  font-size: 6vh;
  text-align: center;
  position: relative;
  margin: auto auto 25px auto;
  max-width: 90%;
}

.home>div>a {
  margin: 10px auto;
  padding: 15px 45px;
  background-color: var(--c2d1);
  color: white;
  border-radius: 50px;
}

/* Bannière */
.hBanner {
  width: 100%;
  background-repeat: no-repeat;
  position: relative;
  .slick-dots li button {
    background-color: #efefef;
  }
  
  .slick-dots li.slick-active button {
    background-color: var(--colorPrimary);
  }
  
  .hb-textAndImage {
    width: 100%;
  }
}



.hbt-item {
  height: 100vh;
  display: flex;
  width: 100%;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0;
    background: linear-gradient(90deg, rgba(2, 59, 119, 0.92) 46.88%, rgba(2, 59, 119, 0) 100%);
  }
  img {
    width: 100%;
    height: 100%;
  }
  >div {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 90%;
    transform: translate(-50%, -50%);
    z-index: 1000000;
    color: white;
    .hbt-refPg{
      a{
        background-color: white;
        color: var(--colorPrimary);
      }
    }
  }
}

.hb-textAndImage {
  b {
    font-size: 3vh;
    margin-bottom: 25px;
  }
  h1 {
    font-size: 6vh;
    margin-bottom: 25px;
    white-space: wrap;
  }
  section {
    justify-content: flex-start;
    display: flex;
  }
  a {
    color: white;
    padding: 15px 30px;
    border-radius: 30px;
  }
}


.hbt-authAndSimulateLinks {
  display: flex;
  gap: 0 10px;
  a {
    font-weight: 900;
    display: flex;
    align-items: center;
    &:first-child {
      color: var(--colorPrimary);
      background-color: white;
      border: solid 1px white;
    }
    &:last-child {
      background-color: var(--colorSecondary);
    }
  }
}

/*  */
.stealerAlert {
  width: 90%;
  background-color: #E3252E20;
  color: var(--c2d1);
  border-radius: 10px;
  padding: 30px !important;
  display: flex !important;
  flex-direction: row !important;
  gap: 0 10px !important;
  margin-top: 80px !important;
  span {
    font-size: 40px;
  }
}


/*  */
.h-howItWord {
  width: 100%;
  justify-content: center;
}

.hh-title {
  text-align: center;
  max-width: 90%;
  margin: auto;
  p {
    font-size: 26px;
  }
}
.hh-cardsList {
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px 0;
  justify-content: space-evenly;
  position: relative;
}

.hh-cardsList::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 50%;
  background-color: var(--colorPrimary);
  top: 60%;
}

.hh-card {
  width: 22em;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 16px;
  padding: 20px 0;
  /* background-color: var(--ow4) !important; */
  span {
    position: absolute;
    /* top: 20px; */
    left: 20px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    background-color: var(--colorPrimary);
    color: white;
  }
  div {
    width: 90%;
  }
}
/*  */

/* Our task offer */
.hOurTaskOffer {
  width: 100%;
  section {
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
    justify-content: space-evenly;
    div {
      width: 12rem;
      border-radius: 15px;
      box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.11), 0px 1px 10px rgba(0, 0, 0, 0.09), 0px 2px 4px rgba(0, 0, 0, 0.16);
      text-align: center;
      padding: 20px;
      transition: all 0.5s;
      &:hover {
        transform: scale(1.1);
        a {
          color: var(--colorPrimary);
        }
      }
      a {
        color: var(--colorPrimary);
        transition: all 0.5s;
      }

    }
  }
}
/*  */

/* Our result */
.hOurResults {
  width: 100%;
  background-color: var(--colorPrimary);
  margin-bottom: 0 !important;
  h2 {
    max-width: 80%;
    color: white !important;
  }
}

.hOurResults>section {
  width: 100%;
  margin: 20px auto;
  display: flex;
  gap: 20px 0;
  justify-content: space-evenly;
  flex-wrap: wrap;
  div {
    text-align: center;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
      rgba(17, 17, 26, 0.1) 0px 0px 8px;
    padding: 20px 40px;
    width: 20rem;
    background-color: white;
  }
}

.hOurResults section div:last-child {
  background-color: var(--colorSecondary);
  display: flex;
  justify-content: center;
  align-items: center;
}

.hOurResults section div:last-child a {
  color: white;
}

.hOurResults section div {
  span {
    color: var(--colorPrimary);
    display: inline-block;
    font-weight: 900;
    font-size: 30px;
    cursor: pointer;
  }
  p {
    color: grey;
    margin-top: 10px;
  }
}

/*  */



/* User comments */


.hUserComments {
  height: 30vh;
  width: 100%;
  background-image: url("../../images/b1.webp");
  display: flex;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  a, span {
  color: white !important;
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 51, 119, 0.8);
  }
  div {
    max-width: 90%;
    margin: auto;
    text-align: center;
  }
  h2,div {
  z-index: 999;
  }
  h2 {
    color: white !important;
    margin-bottom: 25px;
    font-size: 5vh;
  }
  a {
    font-size: 3vh;
  }
  b {
    color: #00b67a;
  }
}

/*  */

.homeFaq {
  width: 90%;
  margin: auto;
  flex-direction: row !important;
  flex-wrap: wrap;
  gap: 20px 0;
  justify-content: space-evenly;
  h2 {
    margin-left: initial !important;
    text-align: inherit !important;
    max-width: initial !important;
  }
  &>section {
    width: 45%;
  }
}

.hf-title {
  background-image: url('../../images/faq.webp');
  background-repeat: no-repeat;
  background-position: center;
}

.hf-questionList {
  display: flex;
  flex-direction: column;
  gap: 20px 0;
}

/*  */



@media screen and (max-width: 758px) {

  /* Faq */
  .homeFaq>section {
    width: 100%;
  }

  /*  */
}

@media screen and (max-width: 512px) {
  .home>div>h2 {
    font-size: 5vh;
    margin: 10px auto;
  }

  /* Home Banner  */
  .hbt-authAndSimulateLinks a {
    font-size: 14px;
  }


  /*  */

  /* How it work */
  .h-howItWord {}

  .hh-cardsList {}

  .h-howItWord>section>div {
    width: 80%;
    padding: 50px 0;
  }

  /*  */

  /* Whys us */
  .hWhyUs {
    width: 90%;
  }

  .hWhyUs section {}

  .hWhyUs section div {
    width: 80% !important;
    max-width: initial;
  }

  /*  */

  /* Our task offer */
  .hOurTaskOffer {}

  .hOurTaskOffer section div {
    width: 45%;
  }

  /*  */

  /* Our result */
  .hOurResults section div {
    width: 80%;
  }

  /*  */

  /* User comments */
  .hUserComments section>div {
    width: 90%;
  }

  /*  */
}