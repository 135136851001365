.userAuth {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-around;
}

.uaBanner,.uaForm,.ua-SignInfos {
  padding: 50px 0;
}

#authFullBanner {
  width: 90%;
  margin: 10px auto 30px auto;
  text-align: center;
  h1 {
    color: var(--colorPrimary);
  }
  p {
    color: var(--c2d1);
  }
}

.uaBanner {
  width: 50%;
  text-align: center;
  height: 80vh;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  img {
    max-width: 90%;
    height: 90%;
  }
  h2 {
    color: white;
  }
}

.uaForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40%;
  box-shadow: var(--cpd4) 0px 0px 0.25em, var(--cpd4) 0px 0.25em 1em;
  h2 {
    width: 90%;
    margin: 10px 0;
    color: var(--colorPrimary);
  }
}

.checkOutTitle {
  width: 90%;
  margin: auto;
  color: var(--colorSecondary);
  display: flex;
  align-items: center;
  justify-content: space-between;
  img {
    height: 60px;
  }
}

.uafOtherAuth {
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90%;
  margin: auto;
  font-size: 14px;
  text-align: center;
  a {
    color: var(--c2d1);
  }
}

/*  */
.password {
  padding: 50px 0;
  h2 {
    color: var(--colorPrimary);
    max-width: 80%;
    margin: auto;
    text-align: center;
  }
  &>section {
    width: 50%;
    margin: 50px auto;
    padding: 30px 10px;
    background-color: var(--cwd1);
    form {
      color: grey;
      div {
        width: 90%;
        margin: auto;
      }
    }
  }
}

/*  */
@media screen and (max-width: 758px) {
  .uaBanner {
    display: none;
  }

  .ua-SignInfos {
    width: 90%;
  }

  .uaForm {
    width: 90%;
  }
}

@media screen and (max-width: 512px) {
  .password>section {
    width: 90%;
  }
}