/* footer */
.footer {
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
  padding: 70px 0;
  width: 100%;
  background-color: #023A7710;
  display: flex;
  gap: 20px 0;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.footer img {
  width: 50%;
}

.footer>section {
  width: 22%;
  display: flex;
  flex-direction: column;
}

.footer>section b {
  font-size: 25px;
  padding: 10px 0;
  color: var(--colorPrimary);
}

.footer>section div {
  display: flex;
  flex-direction: column;
  gap: 5px 0;
}

.footer>section div a,
.footer>section div p {
  padding: 2px 0;
  color: #333;
}

.footer>section div a {

}
.fContact{
  a{
    color: var(--colorSecondary);
    display: flex;
    align-items: center;
    gap: 0 10px;
    svg{
      font-size: 25px;
    }
  }
}

.footer>section div p span {
  margin-left: auto;
  width: calc(100% - 40px);
}

.footer>.fSocialLinks {
  text-align: center;
  &>a{
    margin-top: 15px;
  }
}


.footer>.fSocialLinks div {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

/*  */



@media screen and (min-width: 512px) and (max-width: 1024px) {
  .footer>section {
    width: 45%;
  }
}

@media screen and (max-width: 512px) {

  /* footer */
  .footer {
    flex-direction: column;
    align-items: center;
  }

  .footer>section {
    width: 90%;
  }


  /*  */
}

@media screen and (max-width: 758px) {
  .footer>.fSocialLinks div a span {
    display: none;
  }
}