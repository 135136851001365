.maintenancePage {
  /* height: 80vh; */
  padding: 250px 0 50px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.maintenancePage h1 {
  color: var(--colorPrimary);
  font-size: 35px;
}

.maintenancePage img {
  height: 40vh;
}

.maintenancePage p {
  font-size: 25px;
  text-align: center;
}