.prelevementForm {
    padding: 250px 0;
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: column;
    h1 {
        color: var(--colorPrimary);
    }
    p,h1, h3 {
    text-align: center;
    }
    h3 {
        color: #333;
    }
    form {
        width: 50%;
        margin: 25px auto;
        a {
            padding: 10px 15px;
            background-color: var(--colorPrimary);
            color: white;
        }
    }
    ul,p, b {
    color: var(--colorSecondary);
    }
    input {
        background-color: var(--cwd1);
    }
}

@media screen and (max-width: 512px) {
    .prelevementForm form {
        width: 100%;
    }
}