/* INPUT */
input:-webkit-autofill,
input:-moz-autofill,
input:-webkit-autofill:hover,
input:-moz-autofill:hover,
input:-webkit-autofill:focus,
input:-moz-autofill:focus,
input:-webkit-autofill:active,
input:-moz-autofill:active,
select:-webkit-autofill,
select:-moz-autofill,
select:-webkit-autofill:hover,
select:-moz-autofill:hover,
select:-webkit-autofill:focus,
select:-moz-autofill:focus,
select:-webkit-autofill:active,
select:-moz-autofill:active {}

button {
  border: none;
  padding: 5px 10px;
}

input,
select,
textarea,
button {
  transition: all 0.5s;
}

input,
select,
textarea {
  padding: 20px 10px;
  border: 0;
  border-bottom: solid 2px var(--cwd2);
}

input[type="radio"] {
  background-color: white;
}

input[type="file"] {
  display: none;
}

input:focus:not([type="radio"]),
textarea:focus,
select:focus {
  outline: none !important;
  border-bottom: solid 2px var(--colorPrimary) !important;
  background-color: white;
}

textarea {
  box-sizing: border-box;
}

input[type="number"] {
  cursor: pointer;
}


form{
    display: flex;
    flex-direction: column;
    gap: 15px 0;
    width: 90%;
}

.formSegment {
    width: 100%;
  display: flex;
  flex-direction: column;
  margin: 5px auto;
  gap: 10px 0;
  >label {
    padding: 5px 0;
    font-size: 18px;
    font-weight: bolder;
  }
  section {
    position: relative;
    display: flex;
    input,select,textarea {
        width: 100%;
        overflow: hidden;
      }
  }
}


/* Form Btn */
.formBtn {
  margin: 10px auto;
  width: 100%;
  button {
    background-color: var(--colorPrimary);
    color: white;
    transition: all 0.5s;
    padding: 15px 35px;
    font-size: 120%;
  }
}
/* Form Password field icon */
.password-icon,
.password-icon-visible {
  position: absolute;
  right: 0;
  height: 100%;
  padding: 0 15px;
  text-align: center;
  display: flex;
  align-items: center;
  top: 0px;
  bottom: 0px;
  cursor: pointer;
  transition: all 0.5s;
  border-radius: 10px;
  font-size: 15px;
}

.password-icon {
  transition: all 0.5s;
  color: grey;
}

.password-icon-visible {
  color: var(--colorPrimary);
}

/* Field text search */
.formSegmentSearch {
  position: relative;
}

.fss-suggestions {
  z-index: 999999999;
  position: absolute;
  top: 105%;
  width: 100%;
  max-height: 130px;
  background-color: var(--cwd2);
  border-radius: 10px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}
.fss-suggestions span {
  padding: 10px 15px;
  cursor: pointer;
}

.fss-suggestions span:hover {
  background-color: var(--colorPrimary);
  color: white;
}


.formSegmentCheckbox{
    section{
        gap: 10px 0;
        input{
            width: initial !important;
        }
        label{
            width: 100%;
        }
    }
}
.formSegmentFiles{
    .fsf-files{
        gap: 20px 0;
        article{
            background-color: #efefef;
            padding: 30px;
            border-radius: 10px;
            position: relative;
            div{
                position: absolute;
                right: 10px;
                bottom: -10px;
                gap: 0 10px;
                span{
                    cursor: pointer;
                    // display: block;
                    background-color: var(--colorSecondary);
                    color: white;
                    border-radius: 10px;
                    padding: 8px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
       
    }
    .fsf-newFile{
        gap: 10px 0;
        label{
            background-color: var(--colorPrimary);
           margin-right: auto;
            padding: 10px;
            border-radius: 10px;
            color: white;
            margin-top: 15px;
        }
    }
}

/*  */
.formSegmentTags {
  width: 100%;
  margin: 15px auto;
  display: flex;
  flex-direction: column;
  span {
    cursor: pointer;
    margin: 10px auto 10px 0;
  }
}

.tagReturnBtn {
  background-color: var(--cwd3);
  color: var(--color4);
  padding: 10px 15px;
  border-radius: 10px;
}

.tagAddBtn {
  background-color: var(--colorPrimary);
  color: white;
  padding: 10px 15px;
  border-radius: 10px;
}

.formSegmentTags label {
  font-weight: bolder;
}


.fst-form {
    width: 100%;
  margin: 0 auto 10px auto;
    input {
        width: 100%;
        margin: 10px auto;
      }
      div {
        display: flex;
        gap: 10px;
      }
}

.fst-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 10px 0;
  p {
    background-color: var(--colorSecondary);
    padding: 2px 5px;
    border-radius: 10px;
  }
  i {
    background-color: white;
    color: var(--colorSecondary);
    border-radius: 100%;
    margin-left: 5px;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    display: inline-block;
  }
}
/*  */

/* Other auth */
.log-or-register {
  width: 100%;
  text-align: center;
  p {
    color: grey;
  }
  a {
    color: var(--colorPrimary);
  }
}

@media screen and (max-width: 758px) {

  input,
  select,
  textarea {
    padding: 18px 10px;
  }
}