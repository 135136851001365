.modal {
  background-color: var(--ob8);
  position: fixed;
  top: 0 !important;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000 !important;
  &>section {
    background-color: white;
    padding: 10px 20px 30px 20px;
    border-radius: 10px;
    animation: modalFade 0.6s ease-in-out;
    overflow-y: auto;
    max-height: 90%;
    max-width: 90%;
  }
}

.mb-head-closer,
.mb-foot-closer {
  width: 100%;
  margin: auto;
  padding: 10px 0;
}

.mb-head-closer {
  text-align: right;
  cursor: pointer;
  svg {
    cursor: pointer;
    font-size: 35px;
  }
}

/* COnfirmation */

.confirmationModal {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  section {
    display: flex;
    gap: 20px;
    margin: 25px auto;
    span {
      padding: 10px 15px;
      cursor: pointer;
      &:nth-child(1) {
        background-color: var(--colorPrimary);
        color: white;
      }
      &:nth-child(2) {
        background-color: var(--cwd4);
        color: grey;
      }
    }
  }
}


/* illustrationAndTextMessageModal */
.illustrationAndTextMessageModal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 300px;
  h2 {
    color: var(--colorPrimary);
  }
}

/*  */
/*  */
@keyframes modalFade {
  from {
    transform: translateY(-50%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}



@media screen and (max-width: 758px) {
  .modal>section {
    width: 80%;
  }
}

@media screen and (min-width: 758px) and (max-width: 1024px) {
  .modal>section {
    width: 60%;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1200px) {
  .modal>section {
    width: 40%;
  }
}

@media screen and (min-width: 1200px) {
  .modal>section {
    width: 30%;
  }
}

/* @media screen and (max-width: 758px) {
  
} */