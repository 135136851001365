
    .simulateur-age.revenuMulti{
    align-items: flex-end;
    }
.simulateur-age.revenuMulti>div>input{
    width: 220px !important;
    min-width: 135px;
    margin: 20px 10px !important;
    font-size: 30px;
    color: #3f7bc9 !important;
}

.revenuMulti>span {
    font-size: 30px !important; 
    color: #1174ea; 
    margin-bottom: 21.5px;
}

@media screen and (max-width:700px) {
    .simulateur-age.revenuMulti>div>input{
        width: 175px !important;
        font-size: 30px;
    }
    .simulateur-age.revenuMulti{
        flex-direction: row !important;
    }
    
}