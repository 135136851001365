.tableContainer {
  width: 100%;
  overflow-x: auto;
}

/*  */
.fichier {
  width: 90%;
}
/* Ressources */
.ressources>section {
  min-width: 50%;
  margin: auto;
  padding: 30px 20px;
}

.fSelectTab {
  margin: 25px auto;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  border-radius: 20px;
  text-align: center;
  width: 40%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  padding: 5px;
  label {
    width: 50%;
    display: flex;
    span {
      padding: 15px 10px;
      border-radius: 20px;
      margin: auto;
    }
  }
  input {
    display: none;
    &:checked+span {
      background-color: var(--colorPrimary);
      color: white;
      width: 100%;
    }
  }
}


.ressources>section{
  &>h2 {
    color: var(--c2d1);
    text-align: center;
    margin-bottom: 25px;
  }
  &>a {
    color: grey;
    text-align: center;
    margin: auto;
    width: 100%;
    margin: 0 0 25px 0;
    font-size: 25px;
    a {
      background-color: var(--c2d1);
      color: white;
      padding: 5px 10px;
      border-radius: 50px;
    }
  }
  &>div {
    background-color: white;
    padding: 25px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 20px;
    margin: 20px 0;
  }
  p {
    color: grey;
  }
}

/*  */

@media screen and (max-width: 758px) {
  .fichier {
    width: 98%;
  }

  .fSelectTab {
    width: 90%;
  }


}