.simulateur-age {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.simulateur-age>span {
  font-size: 30px;
  letter-spacing: 0.08px;
  font-weight: 500;
}

.simulateur-age>div {
  position: relative;
}

.simulateur-age>div>span {
  position: absolute;
  display: block;
  background: #2894ff;
}

.simulateur-age>div>span:nth-child(2) {
  left: 20px;
  bottom: 0;
  width: 2px;
  height: 100%;
  transform: scaleY(0);
  transform-origin: top;
  transition: transform 0.25s;
}

.simulateur-age>div:hover>span:nth-child(2) {
  transform: scaleY(1);
  transform-origin: bottom;
  transition: transform 0.25s;
}

.simulateur-age>div>span:nth-child(3) {
  left: 20px;
  top: 0;
  height: 2px;
  width: calc(100% - 40px);
  width: -moz-calc(100% - 40px);
  width: -webkit-calc(100% - 40px);
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.25s;
}

.simulateur-age>div:hover>span:nth-child(3) {
  transform: scaleX(1);
  transform-origin: left;
  transition: transform 0.25s;
}

.simulateur-age>div>span:nth-child(4) {
  right: 20px;
  bottom: 0;
  width: 2px;
  height: 100%;
  transform: scaleY(0);
  transform-origin: bottom;
  transition: transform 0.25s;
}

.simulateur-age>div:hover>span:nth-child(4) {
  transform: scaleY(1);
  transform-origin: top;
  transition: transform 0.25s;
}

.simulateur-age>div>span:nth-child(5) {
  left: 20px;
  bottom: 0;
  height: 2px;
  width: calc(100% - 40px);
  width: -webkit-calc(100% - 40px);
  width: -moz-calc(100% - 40px);
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.25s;
}

.simulateur-age>div:hover>span:nth-child(5) {
  transform: scaleX(1);
  transform-origin: right;
  transition: transform 0.25s;
}

.simulateur-age>div>input {
  width: 200px;
  color: #023880 !important;
  cursor: pointer;
  font-family: Avenir;
  font-weight: 700;
  font-size: 100px;
  letter-spacing: 0.27px;
  min-width: 185px;
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
  background: transparent;
  outline: none;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  overflow: hidden;
  margin: 0 20px;
  border: 1px solid #e9e9e9;
  /* border-radius: 8px; */
  --webkit-appearance: none !important;
}

.simulateur-date {
  text-align: center;
}

.simulateur-date>span {
  font-size: 30px;
  letter-spacing: 0.08px;
  font-weight: 500;
  display: block;
}

.simulateur-date>div {
  position: relative;
  margin: 20px auto;
  width: fit-content;
}

.simulateur-date>div>span {
  position: absolute;
  display: block;
  background: #2894ff;
}

.simulateur-date>div>span:nth-child(2) {
  left: 0px;
  bottom: 0;
  width: 2px;
  height: 100%;
  transform: scaleY(0);
  transform-origin: top;
  transition: transform 0.25s;
}

.simulateur-date>div:hover>span:nth-child(2) {
  transform: scaleY(1);
  transform-origin: bottom;
  transition: transform 0.25s;
}

.simulateur-date>div>span:nth-child(3) {
  left: 0px;
  top: 0;
  height: 2px;
  /* width: calc(100% - 40px);
    width: -moz-calc(100% - 40px);
    width: -webkit-calc(100% - 40px); */
  width: 100%;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.25s;
}

.simulateur-date>div:hover>span:nth-child(3) {
  transform: scaleX(1);
  transform-origin: left;
  transition: transform 0.25s;
}

.simulateur-date>div>span:nth-child(4) {
  right: 0px;
  bottom: 0;
  width: 2px;
  height: 100%;
  transform: scaleY(0);
  transform-origin: bottom;
  transition: transform 0.25s;
}

.simulateur-date>div:hover>span:nth-child(4) {
  transform: scaleY(1);
  transform-origin: top;
  transition: transform 0.25s;
}

.simulateur-date>div>span:nth-child(5) {
  left: 0px;
  bottom: 0;
  height: 2px;
  /* width: calc(100% - 40px);
    width: -webkit-calc(100% - 40px);
    width: -moz-calc(100% - 40px); */
  transform: scaleX(0);
  width: 100%;
  transform-origin: left;
  transition: transform 0.25s;
}

.simulateur-date>div:hover>span:nth-child(5) {
  transform: scaleX(1);
  transform-origin: right;
  transition: transform 0.25s;
}

.simulateur-date>div>input {
  /* width:200px; */
  color: #023880 !important;
  cursor: pointer;
  font-family: Avenir;
  font-weight: 700;
  font-size: 80px;
  letter-spacing: 0.27px;
  min-width: 185px;
  text-align: center;
  padding: 10px;
  background: transparent;
  outline: none;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  overflow: hidden;
  border: 1px solid #e9e9e9;
  /* border-radius: 8px; */
  --webkit-appearance: none !important;
}

.button-simulateur {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin: 40px auto;
}

.button-simulateur>button>span {
  margin: 0 10px;
}

.simulateur-nationalite>span {
  font-weight: 500;
  font-size: 30px;
  text-align: center;
  display: inline-block;
  width: 100%;
}

.simulator-subTitle + div{
  border-radius: 10px;
  margin: 15px auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.simulateur-nationalite>div:nth-child(2) {
  border-radius: 10px;
  margin: 15px auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.simulateur-nationalite>div:nth-child(2)>div,
.simulateur-nationalite>div:nth-child(3)>div {
  position: relative;
  width: 90%;
  max-width: 514px;
  margin-bottom: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d1cfcf;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.3s ease;
  padding: 15px 15px;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}

.simulateur-nationalite.nation>div:nth-child(2)>div {
  flex-direction: column;
}

.simulateur-nationalite>div:nth-child(2)>div:hover {
  color: var(--colorPrimary);
  border: 1px solid var(--colorPrimary);
}

.simulateur-nationalite>div:nth-child(2)>div.selected,
.simulateur-nationalite>div:nth-child(3)>div.selected {
  color: white;
  background-color: var(--colorPrimary);
  border: 1px solid var(--colorPrimary);
}

@media screen and (max-width: 900px) {
  .simulateur-date>div>input {
    font-size: 60px;
  }

  .simulateur-date>span {
    font-size: 25px;
  }
}

@media screen and (max-width: 700px) {
  .simulateur-age {
    flex-direction: column;
  }

  .simulateur-age>div>input {
    margin: 10px 0;
    font-size: 60px;
  }

  .simulateur-age>div>span:nth-child(2) {
    left: 0px;
    bottom: 10px;
    height: calc(100% - 20px);
    height: -webkit-calc(100% - 20px);
    height: -moz-calc(100% - 20px);
  }

  .simulateur-age>div>span:nth-child(3) {
    left: 0px;
    top: 10px;
    width: 100%;
  }

  .simulateur-age>div>span:nth-child(4) {
    right: 0px;
    bottom: 10px;
    height: calc(100% - 20px);
    height: -webkit-calc(100% - 20px);
    height: -moz-calc(100% - 20px);
  }

  .simulateur-age>div>span:nth-child(5) {
    left: 0px;
    bottom: 10px;
    width: 100%;
  }

  .simulateur-date>div>input {
    font-size: 40px;
  }
}

@media screen and (max-width: 500px) {
  .button-simulateur {
    flex-direction: column;
    margin-top: 30px;
  }

  .button-simulateur>button {
    margin: 10px auto;
  }

  .simulateur-nationalite.nation>div:nth-child(2)>div {
    min-height: 96px;
    width: 100%;
    font-size: 18px;
  }

  .simulateur-nationalite>div:nth-child(2)>div {
    width: 100%;
    font-size: 18px;
  }

  .simulateur-nationalite>span {
    font-size: 25px;
  }

  .simulateur-date>div>input {
    font-size: 28px;
    padding: 5px !important;
  }

  .simulateur-date>span {
    font-size: 23px;
  }
}