.free-simulation {
  margin-left: auto !important;
  margin-right: auto !important;
}

.simulateur_page {
  padding: 150px 0;
  display: flex;
  gap: 20px 0;
  flex-direction: column;
}

.simulateur_page>h2 {
  text-align: center;
  color: var(--colorPrimary);
}

.top_simulateur>span {
  display: inline-block;
  text-align: center;
  font-size: 1.75rem;
  width: 100%;
  font-weight: 600;
}

.simulateur_content {
  width: 60%;
  margin: auto;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
  color: black;
  border-radius: 16px;
  transition: 0.4s ease;
  padding: 20px 0;
}

.simulateur_tag {
  max-width: 90%;
  margin: auto;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
  border: solid 2px #efefef;
  margin-bottom: 20px;
}

.simulateur_tag>h3 {
  color: var(--colorPrimary);
  font-weight: 600;
}

.simulateur_wrap {
  width: 90%;
  margin: auto;
  transition: 0.3s ease;
}

.simulateur_wrap>div {
  animation: moveUp 0.6s 1 ease;
}

.modal-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999999999999999999;
}

.modal-wrap>div {
  display: block;
  max-width: 540px;
  width: 95%;
  max-height: 90%;
  margin: 30px auto auto;
  box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
  border: 3px solid white;
  background-color: white;
  overflow-y: auto;
  position: relative;
  border-radius: 10px;
}

.modal-wrap>div>div:first-child {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 27px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--colorPrimary);
  cursor: pointer;
  transition: 0.2s;
  z-index: 4;
}

.modal-wrap>div>div:nth-child(2) {
  padding: 27px 20px;
  color: #000;
  font-size: 22px;
  border-bottom: 1px solid #e5e5e5;
  font-weight: 500;
}

.modal-wrap>div>div:nth-child(3) {
  padding: 15px;
}

.modal-wrap>div>div:nth-child(3) .form-group>label {
  /* color: #000 !important; */
  font-size: 20px !important;
  margin-bottom: 15px !important;
  text-align: center;
  width: 100%;
  color: var(--colorPrimary);
}

.modal-wrap>div>div:nth-child(3) .form-group>input {
  color: var(--colorPrimary) !important;
  padding: 12px 10px !important;
  border: 1px solid #e9e9e9 !important;
  font-size: 20px !important;
  font-weight: 700 !important;
  height: auto !important;
  text-align: center;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.modal-wrap>div>div:nth-child(3) .form-group>select {
  padding: 12px 10px !important;
  border: 1px solid #e9e9e9 !important;
  font-size: 20px !important;
  font-weight: 500 !important;
  height: auto !important;
}

.enfant-modal-selection {
  margin-bottom: 1rem;
}

.enfant-modal-selection>span {
  color: var(--colorPrimary);
  font-size: 20px;
  margin-bottom: 15px;
  display: block;
  font-weight: bolder;
  text-align: center;
}

.enfant-modal-selection>div {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.enfant-modal-selection>div>div {
  max-width: 514px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d1cfcf;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.3s ease;
  padding: 15px 15px;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  width: 30%;
  margin: 0 5px;
}

.enfant-modal-selection.handicap-wrap>div>div {
  font-size: 14px;
  height: 100%;
}

.enfant-modal-selection>div>div:hover {
  color: var(--colorPrimary);
  border: 1px solid var(--colorPrimary);
}

.enfant-modal-selection>div>div.selected {
  color: white;
  background-color: var(--colorPrimary);
  border: 1px solid var(--colorPrimary);
}

.simulateur-nationalite.modal-enfant>span {
  font-weight: bolder !important;
  font-size: 20px !important;
  color: var(--colorPrimary) !important;
}

.revenu-modal>span {
  font-weight: bolder !important;
  font-size: 20px !important;
  color: var(--colorPrimary) !important;
  text-align: center;
  display: inline-block;
  width: 100%;
  margin-bottom: 15px;
}

.revenu-modal>div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.simulateur-age.revenu>div>input {
  width: 7ch !important;
  min-width: 135px;
  margin: 20px 10px !important;
  font-size: 40px;
  color: var(--colorPrimary) !important;
}

.revenu>span {
  font-size: 40px !important;
  color: var(--colorPrimary);
}

.button-simulateur button {
  padding: 10px 15px;
}

@media screen and (max-width: 758px) {
  .simulateur_content {
    width: 90%;
  }

}

@media screen and (max-width: 500px) {

  .modal-wrap>div>div:nth-child(3) .form-group>input,
  .modal-wrap>div>div:nth-child(3) .form-group>select {
    font-size: 18px !important;
  }

  .modal-wrap>div>div:nth-child(2) {
    padding: 25px 17px;
  }

  .enfant-modal-selection>div.echelon-bourse-enfant>div {
    width: auto;
    padding: 11px 7px;
    border-radius: 5px;
  }

  .modal-wrap>div {
    border-radius: 8px;
  }

  .simulateur-nationalite.modal-enfant>div:nth-child(2)>div {
    min-height: auto !important;
    width: 80% !important;
    padding: 10px 12px !important;
    border-radius: 8px !important;
  }

  .modal-wrap>div>div:nth-child(3) .form-group>input {
    width: 80% !important;
    padding: 10px 12px !important;
  }

  .enfant-modal-selection.handicap-wrap>div>div {
    padding: 15px 8px;
  }

  .simulateur_page>div {
    padding: 20px 0;
  }

  .simulateur_content {
    padding: 20px;
  }
}