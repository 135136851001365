/* A propos */
.aboutPart {
  width: 90%;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
  color: white;
  .abp-Text {
    color: white;
    margin: 20px 0 20px 0;
    width: 30%;
    p {
      line-height: 30px;
      font-size: 12px;
    }
  }
  .abpImg {
    width: 30%;
    display: flex;
    img {
      width: 100%;
    }
  }
}


@media screen and (max-width: 512px) {
  .abp-Text {
    width: 90%;
  }
  .abpImg {
    margin: auto;
  }
}
